import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IProduct } from '../shared';
import { NavigationService } from 'src/app/nav/nav.service';

@Component({
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css']
})
export class ProductDetailsComponent implements OnInit {
  product: IProduct;
  isEditable = false;
  afuConfig: any;
  productImage: string;

  constructor(
    private prod: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private nav: NavigationService
    ) { }

  ngOnInit() {
    this.getProduct();

    this.prod.selected_product_updated$.subscribe(() => { this.getProduct(); });

    this.nav.setNavButtonRight(3);
  }

  onImagesUploaded(isImgUploaded: boolean) {
    if (isImgUploaded) { this.getProduct(); }
  }

  onImagesDeleted(isImgDeleted: boolean) {
    if (isImgDeleted) { this.getProduct(); }
  }

  getProduct() {
    this.prod.getProduct(+this.route.snapshot.params['id']).subscribe(data => {
      this.product = data;
      this.productImage = this.prod.getProductImageUri(data, 99);
      if (this.productImage) {
        console.log('Hej, her er dit billede: ' + this.productImage);
      } else {
        this.productImage = this.prod.getProductImageUri(data, 2);
      }
      if (!this.productImage) {
        this.productImage = this.prod.getProductImageUri(data, 1);
      }

      this.nav.setPageTitle(this.product.ModelDescription);

      this.nav.setNavButtonLeft(1);
      this.nav.setNavButtonMain(this.product.ProductGroupId);
      this.nav.setNavButtonRight(3);

      this.prod.setSelectedProduct(this.product);

this.nav.click_button_left.subscribe(() => {
  this.router.navigate(['/products/' + this.prod.getProductGroupLink(this.product.ProductGroupId)]);
});

/*       const token = sessionStorage.getItem('danbase-token');

      this.afuConfig = {
        multiple: true,
        formatsAllowed: '.jpg,.png',
        maxSize: '20',
        uploadAPI: {
          url: 'https://adminapi.danbase.dk/v1/products/' + this.product.Id + '/images',
          headers: {
         'Content-Type' : 'text/plain;charset=UTF-8',
         'Authorization' : `Bearer ${token}`
          }
        },
        theme: 'dragNDrop',
        hideProgressBar: false,
        hideResetBtn: true,
        hideSelectBtn: false
    };
 */  },
 error => this.router.navigate(['/']));
  }

  getProductGroupLink() {
    if (this.product) {
return this.prod.getProductGroupLink(this.product.ProductGroupId);
    }
    return false;
  }
}
