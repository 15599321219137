import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { NavBarComponent } from './nav/nav-bar.component';
import { AngularFileUploaderModule } from 'angular-file-uploader';

import {
  DashboardComponent,
  VersionComponent
} from './home/index';

import {
  ProductListComponent,
  ProductThumbnailComponent,
  ProductDetailsComponent, ProductDetailsModelinfoComponent, ProductDetailsImagesComponent,
  ProductsService,
  ProductRouteActivator,
  ProductListRouteActivator,
  ProductCreateComponent,
  FilterProductsPipeHasImages,
  FilterProductsPipeNoImages
} from './products/index';

import { Error404Component } from './errors/404.component';
import { AuthService } from './user/shared/auth.service';
import { AuthInterceptor } from './user/shared/auth.interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NavTitleComponent } from './nav/nav-title.component';
import { NavigationService } from './nav/nav.service';
import { ReactiveFormsModule } from '@angular/forms';
import { FilterProductsPipe } from './products/shared/products-filter.pipe';

@NgModule({
  declarations: [
    AppComponent,
    NavBarComponent,
    NavTitleComponent,
    DashboardComponent,
    VersionComponent,
    ProductListComponent,
    ProductThumbnailComponent,
    ProductDetailsComponent,
    ProductDetailsModelinfoComponent,
    ProductDetailsImagesComponent,
    ProductCreateComponent,
    Error404Component,
    FilterProductsPipe,
    FilterProductsPipeHasImages,
    FilterProductsPipeNoImages
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(AppRoutes),
    HttpClientModule,
    AngularFileUploaderModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'})
  ],
  providers: [
    ProductsService,
    ProductRouteActivator,
    ProductListRouteActivator,
    AuthService,
    NavigationService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
