import { Component } from '@angular/core';
import { NavigationService } from './nav.service';

@Component({
  selector: 'app-nav-title',
  templateUrl: 'nav-title.component.html',
  styleUrls: ['nav-title.component.css']
})
export class NavTitleComponent {
  pageTitle = '';

  constructor(private nav: NavigationService) {
    nav.page_title$.subscribe(title => this.pageTitle = title);
  }

}
