import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  templateUrl: './product-create.component.html'
})
export class ProductCreateComponent implements OnInit{
  product: any

  constructor(private productsService: ProductsService, private route:ActivatedRoute) {
    
  }

  ngOnInit() {
    //this.product = this.productsService.getProduct(+this.route.snapshot.params['id'])
  }
}
