import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ProductsService } from '../shared/products.service';

@Injectable()
export class ProductListResolver implements Resolve<any> {
    constructor (private productService: ProductsService) {

    }

    resolve() {
        return false; // this.productService.getProducts().map(products => products)
    }
}
