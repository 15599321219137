import { Component } from '@angular/core';

@Component ({
    selector: 'app-version',
    template: '<div class="version-info">Version: {{version}}</div>',
    styles: [`
    .version-info {
        position: absolute;
        left: 0px;
        right: 0px;
        bottom: 80px;
        width: 100%;
        text-align: center;
        font-size: 11px;
        color: #999999;
    }
    `]
})
export class VersionComponent {
    version = '1.0.5';
}
