import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProductsService } from '../shared/products.service';

@Injectable()
export class ProductListRouteActivator implements CanActivate {
    constructor(private productService: ProductsService, private router: Router) {

    }

    canActivate(route: ActivatedRouteSnapshot) {
        const productExists = this.productService.checkProductName(route.params['id']);

        if (!productExists) {
            this.router.navigate(['/404']);
        }
        return productExists;
    }
}
