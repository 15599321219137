import { Injectable, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class NavigationService {
    @Output() click_button_left: EventEmitter<boolean> = new EventEmitter();
    @Output() click_button_main: EventEmitter<boolean> = new EventEmitter();
    @Output() click_button_right: EventEmitter<boolean> = new EventEmitter();

    // Observable string sources
    private page_title = new Subject<string>();

    private nav_button_left = new Subject<number>();
    private nav_button_main = new Subject<number>();
    private nav_button_right = new Subject<number>();

    private nav_info_left = new Subject<number>();
    private nav_info_right = new Subject<number>();

    // Observable string streams
    page_title$ = this.page_title.asObservable();

    nav_button_left$ = this.nav_button_left.asObservable();
    nav_button_main$ = this.nav_button_main.asObservable();
    nav_button_right$ = this.nav_button_right.asObservable();

    nav_info_left$ = this.nav_info_left.asObservable();
    nav_info_right$ = this.nav_info_right.asObservable();

    constructor() { }

    // Service message commands
    setPageTitle(title: string) {
        this.page_title.next(title);
    }

    setNavButtonLeft(bkey: number) {
        this.nav_button_left.next(bkey);
    }
    setNavButtonMain(bkey: number) {
        this.nav_button_main.next(bkey);
    }
    setNavButtonRight(bkey: number) {
        this.nav_button_right.next(bkey);
    }

    clickNavButtonLeft() {
        this.click_button_left.emit(true);
    }
    clickNavButtonMain() {
        this.click_button_main.emit(true);
    }
    clickNavButtonRight() {
        this.click_button_right.emit(true);
    }
}
