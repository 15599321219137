
import { Component } from '@angular/core';
import { NavigationService } from 'src/app/nav/nav.service';
import { AuthService } from 'src/app/user/shared/auth.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'dashboard.component.html',
  styleUrls: ['dashboard.component.css']

})
export class DashboardComponent {
  navProductList = '';

  constructor(private nav: NavigationService, private auth: AuthService) {
    this.nav.setPageTitle('Dashboard');

    this.nav.setNavButtonLeft(0);
    this.nav.setNavButtonMain(0);
    this.nav.setNavButtonRight(0);

    this.navProductList = this.auth.getFirstModuleLink();
  }
}
