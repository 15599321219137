import { Injectable } from '@angular/core';
import { Observable, throwError, Subject } from 'rxjs';
import { IProduct } from './product.model';
import { HttpClient, HttpParams, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { AuthService } from 'src/app/user/shared/auth.service';
import { IProductImage } from './product-image.model';

@Injectable()
export class ProductsService {
  private selected_product = new Subject<IProduct>();
  selected_product$ = this.selected_product.asObservable();

  private selected_product_updated = new Subject<boolean>();
  selected_product_updated$ = this.selected_product_updated.asObservable();

  private file_upload_count = 0;
  private file_upload_count_updated = new Subject<number>();
  file_upload_count_updated$ = this.file_upload_count_updated.asObservable();

  constructor(
    private http: HttpClient,
    private auth: AuthService,
  ) { }

  private handleError(error: HttpErrorResponse, auth: AuthService) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log('trying to relogin');
      // this.auth.checkAuthenticationStatus();
      auth.logout();
      auth.checkAuthenticationStatus();

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  setSelectedProduct(product: IProduct) {
    this.selected_product.next(product);
  }

  getProducts(name: string): Observable<IProduct[]> {
    // const _token = this.auth.getToken(); // sessionStorage.getItem('danbase-token');
    const groupId = this.getProductGroupId(name);

    if (groupId > 0) {
      return this.http.get<IProduct[]>('https://adminapi.danbase.dk/v1/products',
        { params: new HttpParams().set('ProductGroupId', '' + groupId) })
        .pipe(
          tap(results => {
            results.sort((x, y) => x.ModelDescription < y.ModelDescription ? -1 : 1);
          }))
        .pipe(
          catchError(error => this.handleError(error, this.auth))
        );
    }
  }

  getProduct(id: number): Observable<IProduct> {
    return this.http.get<IProduct>('https://adminapi.danbase.dk/v1/products/' + id).pipe(
      catchError(error => this.handleError(error, this.auth))
    );
  }


  checkProductName(name: string): boolean {
    console.log(name);
    return (this.getProductGroupId(name) > 0);
  }

  getProductGroupId(name: string): number {
    switch (name) {
      case 'caravan':
        return 1;
      case 'tent':
        return 2;
      case 'boat':
        return 3;
      case 'mc':
        return 4;
      case 'car':
        return 5;

      default:
        return -1;
    }
  }
  getProductGroupLink(groupId) {
    switch (groupId) {
      case 2:
        return 'tent';
      case 3:
        return 'boat';
      case 4:
        return 'mc';
      case 5:
        return 'car';
      default:
        return 'caravan';
    }
  }

  getProductImageUri(product: IProduct, imageFormat: number) {
    /* const baseUri = 'https://danbase.dk/Content/Resellers/' + this.auth.getResellerId() + '/'; */
    let image;

    console.log('Antal billeder: ' + product.Images.length);

    /*  if (product.Images.length > 0 && imageFormat === 0) {
         imageFormat = product.Images[0].Formats.find(f => f.Id === 1 || f.Id === 2 || f.Id === 9).Id;
     } */
    product.Images.forEach(img => {
      if (!image) {
        console.log('i ' + img.Formats.length + ' formatter');
        image = this.getProductImageFormatUri(img, product.Id, imageFormat);
      }
    });

    return image ? image : '';
  }

  getProductImageFormatUri(productImage: IProductImage, productId: number, imageFormat: number) {
    const baseUri = 'https://danbase.dk/Content/Resellers/' + this.auth.getResellerId() + '/';
    let image;

    if (productImage && imageFormat === 0) {
      image = productImage.Formats.find(f => f.Id === 1 || f.Id === 2 || f.Id === 9);
    } else {
      image = productImage.Formats.find(f => f.Id === imageFormat);
    }

    return image ? baseUri + productId + '/' + image.Image : '';
  }

  getFileDetails(e, productId: number) {
    // console.log (e.target.files);
    const files: any[] = [];

    for (let i = 0; i < e.target.files.length; i++) {
      files.push(e.target.files[i]);
    }

    this.uploadFiles(productId, files);
  }
  uploadFiles(productId: number, files: any[]) {
    this.addUploadCounter(files.length);

    for (let i = 0; i < files.length; i++) {
      const frmData = new FormData();

      frmData.append('fileUpload', files[i]);

      const options = { headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' }) };
      console.log('uploading image');
      this.http.post('https://adminapi.danbase.dk/v1/products/' + productId + '/images/', frmData, options).subscribe(
        data => {

          // SHOW A MESSAGE RECEIVED FROM THE WEB API.
          const sMsg = data as string;
          console.log(sMsg);

          this.subUploadCounter(1);
          this.selected_product_updated.next(true);
        },
        (err: HttpErrorResponse) => {
          // This is done because of errors from HTTP options request on IOS devices
          this.subUploadCounter(1);
          this.selected_product_updated.next(true);
        }
      );
    }
  }

  addUploadCounter(files_count: number) {
    this.file_upload_count += files_count;
    this.file_upload_count_updated.next(this.file_upload_count);
  }
  subUploadCounter(files_count: number) {
    this.file_upload_count -= files_count;
    this.file_upload_count_updated.next(this.file_upload_count);
  }
}

const PRODUCTS: IProduct[] = [];
