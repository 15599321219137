import { Injectable } from '@angular/core';
import { IUser } from './user.model';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { of, BehaviorSubject, Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class AuthService {
    private _loginUser: any;
    private _currentUser: BehaviorSubject<IUser> = new BehaviorSubject<IUser>({Id: 0, Name: 'Anonumous', Modules: [], Username: ''});
    private jwt_helper = new JwtHelperService();

    public readonly currentUser: Observable<IUser> = this._currentUser.asObservable();

    token: string;

    private requestAccessCode: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    requestAccessCode$ = this.requestAccessCode.asObservable();
    private invalidLogin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    invalidLogin$ = this.invalidLogin.asObservable();

    constructor(
        private http: HttpClient,
        private router: Router) {

    }

    private handleError(error: HttpErrorResponse, auth: AuthService) {
        if (error.error instanceof ErrorEvent) {
            // A client-side or network error occurred. Handle it accordingly.
            console.error('An error occurred:', error.error.message);
        } else if (error.status === 401) {
            auth.requestAccessCode.next(false);
            auth.invalidLogin.next(true);
        } else {
            // The backend returned an unsuccessful response code.
            // The response body may contain clues as to what went wrong,

            console.error(
                `Backend returned code ${error.status}, ` +
                `body was: ${error.error}`);
        }
        // return an observable with a user-facing error message
        return throwError(
            'Something bad happened; please try again later.');
    }

    loginUser(userName: string, password: string) {
        if (userName && password) {
            this._loginUser = { Username: userName, Password: password };
        }

        if (this._loginUser) {
        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        return this.http.post('https://adminapi.danbase.dk/v1/account/login/', this._loginUser, options)
            .pipe(tap(data => {
                this.token = <string>data;
                sessionStorage.setItem('danbase-token', this.token);
                this.checkAuthenticationStatus();
            }, err => {this.requestAccessCode.next(true); }))
            .pipe(
                catchError(error => this.handleError(error, this))
              );
            }
        }

sendAccessCode(accessCode: string) {
    const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    return this.http.post('https://adminapi.danbase.dk/v1/account/validateaccesscode/', accessCode, options)
    .subscribe(success => {
        this.loginUser(undefined, undefined).subscribe(() => {
            this.router.navigate(['/home']);
        });
    }, (err: HttpErrorResponse) => {
        if (err.error instanceof Error) {
          console.log('Client-side error occured.');
        } else {
            console.log('Hurra en error: ' + err.status);
        }
    });
}

setRequestAccessCode(state: boolean) {
    this.requestAccessCode.next(state);
}
setInvalidLogin(state: boolean) {
    this.invalidLogin.next(state);
}

    isAuthenticated() {
        return !!(this._currentUser.getValue && this._currentUser.getValue().Id > 0);
    }

    checkAuthenticationStatus() {
        const _token = sessionStorage.getItem('danbase-token');
        const _isExpired = this.jwt_helper.isTokenExpired(_token);

        if (_token && !_isExpired) {
            // let _headers = new HttpHeaders();
            // _headers.set('Content-Type', 'application/x-www-form-urlencoded');
            // _headers.set('Authorization', "Bearer " + _token);

            return this.http.get('https://adminapi.danbase.dk/v1/account/confirm/')
                .pipe(
                    tap(
                    data => {
                    if (data instanceof Object) {
                        this._currentUser.next(<IUser>data);
                        // this.currentUser.subscribe(res => console.log(res));
                    } else {
                        this.router.navigate(['user/login']);
                    }
                }
            ))
            .pipe(
                catchError(error => this.handleError(error, this))
              )
                .subscribe();
        } else {
            this.router.navigate(['user/login']);
        }

        return false;
    }

    logout() {
        this._currentUser.next({Id: 0, Name: 'Anonumous', Modules: [], Username: ''});
        sessionStorage.removeItem('danbase-token');

        const options = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };

        // return this.http.post('/api/logout', {}, options);
        return true;
    }

    hasModule(id: number) {
        if (this._currentUser && this._currentUser.getValue().Modules) {
            for (let i = 0; i < this._currentUser.getValue().Modules.length; i++) {
                if (this._currentUser.getValue().Modules[i].Id === id) {
                    return true;
                }
            }
        }
        return false;
    }
    getFirstModuleLink() {
        if (this.hasModule(12)) {
            return '/products/caravan';
        }
        if (this.hasModule(11)) {
            return '/products/tent';
        }
        if (this.hasModule(21)) {
            return '/products/boat';
        }
        if (this.hasModule(22)) {
            return '/products/mc';
        }
        if (this.hasModule(23)) {
            return '/products/car';
        }
        if (this.hasModule(211)) {
            return '/products/watersport';
        }
        return '/products/caravan';
    }

    getResellerId() {
        if (this._currentUser && this._currentUser.getValue().ResellerId > 0 ) {
            return this._currentUser.getValue().ResellerId;
        }
        return 0;
    }

    getToken() {
        console.log('getting token');

        const _token = sessionStorage.getItem('danbase-token');
        const _isExpired = this.jwt_helper.isTokenExpired(_token);

        if (!_token) {
            this.logout();
            this.router.navigate(['user/login']);
        }
        if (_isExpired) {
            this.loginUser(undefined, undefined);
        }
        return sessionStorage.getItem('danbase-token');
    }
}
