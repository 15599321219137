import { Component, OnInit } from '@angular/core';
import { ProductsService } from '../shared/products.service';
import { ActivatedRoute, Router } from '@angular/router';
import { IProduct } from '../shared';
import { NavigationService } from 'src/app/nav/nav.service';

@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['product-list.component.css']
})
export class ProductListComponent implements OnInit {
  products: IProduct[];
  showAll = true;
  showMissingImages = false;
  showLatest = false;
  filter = 'all';
  orderby = 'model';

  constructor(
    private productsService: ProductsService,
    private router: Router,
    private route: ActivatedRoute,
    private nav: NavigationService
  ) {

  }

  ngOnInit() {
    this.route.paramMap.subscribe(param => {
      // this.title = param.get('id') + ""
      this.setTitle(param.get('id'));
      this.products = [];
      this.productsService.getProducts(param.get('id'))
        .subscribe(products => {
          this.products = products;
        });

      this.nav.setNavButtonLeft(1);
      this.nav.click_button_left.subscribe(() => {
        this.router.navigate(['/home/']);
      });
      // this.nav.setNavLinkLeft('/home');

      this.nav.setNavButtonRight(0);
    });
  }

  click_filter(button_name: string) {
    this.showAll = false;
    this.showLatest = false;
    this.showMissingImages = false;

    switch (button_name) {
      case 'noimage':
        this.showMissingImages = true;
        this.orderby = 'model';
        this.filter = 'noimage';
        break;
      case 'latest':
        this.showLatest = true;
        this.orderby = 'created';
        this.filter = 'lastest';
        break;
      default:
        this.showAll = true;
        this.orderby = 'model';
        this.filter = 'all';
      break;
    }
  }

  setTitle(id: string) {
    switch (id) {
      case 'caravan':
        this.nav.setNavButtonMain(1);
        // this.nav.setNavLinkMain('products/caravan');
        this.nav.setPageTitle('Campingvogne');
        break;
      case 'tent':
        this.nav.setNavButtonMain(2);
        // this.nav.setNavLinkMain('products/tent');
        this.nav.setPageTitle('Telte');
        break;
      case 'boat':
        this.nav.setNavButtonMain(3);
        // this.nav.setNavLinkMain('products/boat');
        this.nav.setPageTitle('Både');
        break;
      case 'mc':
        this.nav.setNavButtonMain(4);
        // this.nav.setNavLinkMain('products/mc');
        this.nav.setPageTitle('Motorcykler');
        break;
      case 'car':
        this.nav.setNavButtonMain(5);
        // this.nav.setNavLinkMain('products/car');
        this.nav.setPageTitle('Biler');
        break;

      default:
        this.nav.setNavButtonMain(0);
        break;
    }
  }

}
