import { Component } from '@angular/core';
import { AuthService } from './user/shared/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css']
})
export class AppComponent {
  navIsVisible = false;

  constructor(private auth: AuthService) {

  }

  ngOnInit() {
     this.auth.checkAuthenticationStatus();
  }

  onNavbarToggle(isNavbarExpanded: boolean) {
    this.navIsVisible = isNavbarExpanded;
  }

}
