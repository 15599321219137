export * from './list/product-list.component';
export * from './details/product-details.component';
export * from './details/product-details-modelinfo.component';
export * from './details/product-details-images.component';
export * from './product-route-activator.service';
export * from './list/product-list-route-activator.service';
export * from './list/product-list-resolver.service';
export * from './create/product-create.component';
export * from './thumbnail/product-thumbnail.component';
export * from './shared/index';
export * from './shared/products-filter-hasimages.pipe';
export * from './shared/products-filter-noimages.pipe';
