import { Pipe, PipeTransform } from '@angular/core';

import { IProduct } from './index';

@Pipe({ name: 'filterProductsPipeNoImages' })
export class FilterProductsPipeNoImages implements PipeTransform {
  transform(products: IProduct[]) {
      if (!products || products === null) { return products; }
    return products.filter(product => product.StatusCode === 1 && product.ImageCount === 0);
  }
}
