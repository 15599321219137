import { Pipe, PipeTransform } from '@angular/core';

import { IProduct } from './index';

@Pipe({ name: 'filterProductsPipe' })
export class FilterProductsPipe implements PipeTransform {
  transform(products: IProduct[], filter: string, orderby: string, search: string) {
    let filteredProducts = products;

    if (products == null) {
      return products;
    }

    // filter
    if (filter && filter === 'noimage') {
      // no images
      filteredProducts = products.filter(product =>
        product.StatusCode === 1 && product.ImageCount === 0
      );
    } else if (filter && filter === 'lastest') {
      // latest
      filteredProducts = products.filter(product =>
        product.StatusCode === 1 && new Date(product.CreatedAt).getMilliseconds() > (new Date().getMilliseconds())
      );
    } else {
      // all
      filteredProducts = products.filter(product => product.StatusCode === 1);
    }

    // orderby
    if (orderby && orderby === 'created') {
      filteredProducts = filteredProducts.sort(function (a, b) {
        if (a.CreatedAt < b.CreatedAt) {
          return -1;
        }
        if (a.CreatedAt > b.CreatedAt) {
          return 1;
        }
        return 0;
      });
    }


    return filteredProducts;
  }
}
