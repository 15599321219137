import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProductsService } from './shared/products.service';

@Injectable()
export class ProductRouteActivator implements CanActivate {
    constructor(
        private productService: ProductsService,
        private router: Router
        ) {

    }

    canActivate(route: ActivatedRouteSnapshot) {
        this.productService.getProduct(+route.params['id']).subscribe(prod => {
            if (prod != null) {
                return true;
            }
            this.router.navigate(['/home']);
        },
            err => {
                this.router.navigate(['/user/login']);
            });
        return true;
    }
}
