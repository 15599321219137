import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    // check token if expired try silent login
    let _token = sessionStorage.getItem('danbase-token');
    const _isExpired = _token ? new JwtHelperService().isTokenExpired(_token) : false;

    if (!_token || _isExpired) {
      _token = this.auth.getToken();
    }


    // Create a header with auth-token
    const headers = new HttpHeaders({ 'Authorization': 'Bearer ' + sessionStorage.getItem('danbase-token') });

    // Set content-type (default to application/json)
    let contenttype = 'application/json';
    if (req.headers.has('Content-Type')) {
      contenttype = req.headers.get('Content-Type');
    }

    // If content-type is not false, then use it (fixes posting multipart)
    if (contenttype !== 'false') {
      headers.set('Content-Type', contenttype);
    }

    // Make a clone of the request and replace the header with a new
    const duplicate = req.clone({ headers: headers });

    return next.handle(duplicate);
  }
}
