import { Component, Output, EventEmitter } from '@angular/core';
import { AuthService } from '../user/shared/auth.service';
import { NavigationService } from './nav.service';
import { ProductsService, IProduct } from '../products';

@Component({
  selector: 'app-nav-bar',
  templateUrl: 'nav-bar.component.html',
  styleUrls: ['nav-bar.component.css']
})
export class NavBarComponent {
  @Output() navExpanded = new EventEmitter<boolean>();

  isVisible = false;

  showCaravan = false;
  showTent = false;
  showBoat = false;
  showMC = false;
  showCar = false;
  showWatersport = false;

  pageTitle = '';

  navButtonLeft = 0;
  navButtonMain = 0;
  navButtonRight = 0;
  navInfoLeft = 0;
  navInfoRight = 0;

  navLinkLeft = '';
  navLinkMain = '/home';
  navLinkRight = '';

  selectedProduct: IProduct;

  constructor(
    private auth: AuthService,
    private nav: NavigationService,
    private prod: ProductsService) {
    this.navExpanded.emit(this.isVisible);

    nav.page_title$.subscribe(title => this.pageTitle = title);

    nav.nav_button_left$.subscribe(bkey => this.navButtonLeft = bkey);
    nav.nav_button_main$.subscribe(bkey => this.navButtonMain = bkey);
    nav.nav_button_right$.subscribe(bkey => this.navButtonRight = bkey);


    prod.selected_product$.subscribe(product => this.selectedProduct = product);
    prod.selected_product_updated$.subscribe(() => { this.navButtonRight = 3; });

    prod.file_upload_count_updated$.subscribe(count => this.navInfoRight = count);

  /*   nav.nav_link_left$.subscribe(url => this.navLinkLeft = url);
    nav.nav_link_main$.subscribe(url => this.navLinkMain = url);
    nav.nav_link_right$.subscribe(url => this.navLinkRight = url); */

    auth.currentUser.subscribe(() => {
        this.showCaravan = this.auth.hasModule(12);
        this.showTent = this.auth.hasModule(11);
        this.showBoat = this.auth.hasModule(21);
        this.showMC = this.auth.hasModule(22);
        this.showCar = this.auth.hasModule(23);
        this.showWatersport = this.auth.hasModule(211);
    });
  }

  toggle() {
    this.isVisible = !this.isVisible;

    this.navExpanded.emit(this.isVisible);
  }

  click_left() {
    this.nav.clickNavButtonLeft();
  }
  click_main() {
    this.toggle();
    this.nav.clickNavButtonMain();
  }
  click_right() {
    this.nav.clickNavButtonRight();
  }

  click_fileupload(e) {
    this.prod.getFileDetails(e, this.selectedProduct.Id);
  }
}
