import { Component, Input, EventEmitter, Output } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { IProduct, ProductsService } from '../shared';
import { NavigationService } from 'src/app/nav/nav.service';

@Component({
    selector: 'app-product-details-images',
    templateUrl: './product-details-images.component.html'
})
export class ProductDetailsImagesComponent {
    @Input() product: IProduct;
    @Output() imgUploaded = new EventEmitter<boolean>();
    @Output() imgDeleted = new EventEmitter<boolean>();

    showImages: boolean;

    constructor (
      private httpService: HttpClient,
      private nav: NavigationService,
      private prod: ProductsService
      ) {
      this.showImages = false;

      this.nav.click_button_right.subscribe(() => {
        // alert('baah');
        this.isUploadVisible = !this.isUploadVisible;
        this.showImages = !this.showImages;
      });
     }

    myFiles: any [] = [];
    sMsg = '';
    isUploadVisible = false;

    getFileDetails (e) {
      // console.log (e.target.files);
      for (let i = 0; i < e.target.files.length; i++) {
        this.myFiles.push(e.target.files[i]);
      }
    }

    deleteImage(imageId: number) {
      this.httpService.delete('https://adminapi.danbase.dk/v1/products/' + this.product.Id + '/images/' + imageId).subscribe(
        () => {
          this.imgDeleted.emit(true);
        }
      );
    }

    uploadFiles () {
      const frmData = new FormData();

      for (let i = 0; i < this.myFiles.length; i++) {
        frmData.append('fileUpload', this.myFiles[i]);
      }

      const options = {headers: new HttpHeaders({'Content-Type': 'false'})};

      this.httpService.post('https://adminapi.danbase.dk/v1/products/' + this.product.Id + '/images', frmData, options).subscribe(
        data => {
          this.imgUploaded.emit(true);
          this.isUploadVisible = false;

          // SHOW A MESSAGE RECEIVED FROM THE WEB API.
          this.sMsg = data as string;
          console.log (this.sMsg);
        },
        (err: HttpErrorResponse) => {
          console.log (err.message);    // SHOW ERRORS IF ANY.
        }
      );
    }
}
