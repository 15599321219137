import { Component, Input } from '@angular/core';
import { IProduct } from '../shared';

@Component({
    selector: 'app-product-details-modelinfo',
    templateUrl: './product-details-modelinfo.component.html'
})
export class ProductDetailsModelinfoComponent {
    @Input() product: IProduct;

    showPriceInfo: boolean;
    showModelInfo: boolean;

    constructor() {
        this.showPriceInfo = true;
        this.showModelInfo = true;
    }
}
