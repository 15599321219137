import { Routes } from '@angular/router';
import { Error404Component } from './errors/404.component';
import {
    ProductListComponent,
    ProductDetailsComponent,
    ProductRouteActivator,
    ProductListRouteActivator,
    ProductCreateComponent
} from './products/index';
import { DashboardComponent } from './home';

export const AppRoutes: Routes = [
    { path: 'products/new', component: ProductCreateComponent },
    { path: 'products/:id', component: ProductListComponent, canActivate: [ProductListRouteActivator] },
    { path: 'products/details/:id', component: ProductDetailsComponent, canActivate: [ProductRouteActivator] },
    { path: 'home', component: DashboardComponent },
    { path: '404', component: Error404Component },
    { path: '', redirectTo: '/home', pathMatch: 'full' },
    { path: 'user', loadChildren: './user/user.module#UserModule' },
    { path: '**', component: DashboardComponent }
];
